import { Button } from "@o4c/plugin-components";
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import {useInterval} from "react-use";
import { ClockStatus } from "../../App";

interface ITimerButtonProps {
    changeStatus: (status: ClockStatus) => void
    timer: number;
}

export const TimerButton: FunctionComponent<ITimerButtonProps> = (
    props: ITimerButtonProps
) => {
    const {timer, changeStatus} = props;

    const [counter, setCounter] = useState(timer);

    const { t } = useTranslation();

    useInterval(() => {
        if(counter === 0) {
            changeStatus(ClockStatus.OFFLINE);
            return;
        }

        setCounter(counter - 1);
    }, counter >= 0 ? 1000 : null)

    return (
        <Button type="outline" onClick={() => changeStatus(ClockStatus.OFFLINE)}>
            {t("messages.done")} ({counter})
        </Button>
    )
};

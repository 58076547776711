import { FunctionComponent, useEffect, useState } from "react";
import { TextField, } from "@o4c/plugin-components";
import { useTranslation } from "react-i18next";
import styles from "./LoginPage.module.scss"
import NumberPad from "./CustomKeyboard";


interface ILoginPageProps {
    changeEmployeeNumber: (s: string) => void;
    changePincode: (x: string) => void;
}

export const LoginPage: FunctionComponent<ILoginPageProps> = (
    props: ILoginPageProps
) => {
    const { changeEmployeeNumber, changePincode } = props;
    const [employeeNumber, setEmployeeNumber] = useState<string>("");
    const [pincode, setPincode] = useState<string>("");
    const [showNumberPad, setShowNumberPad] = useState(false);
    const [target, setTarget] = useState<"en" | "pn" | "">("")

    const { t } = useTranslation();

    useEffect(() => {
        changeEmployeeNumber(employeeNumber);
    }, [employeeNumber]);

    useEffect(() => {
        changePincode(pincode);
    }, [pincode]);

    //return value is specified in such way from the shared-modules package
    const isRequiredFilled = async (value: string) => {
        return {
            valid: value ? true : false,
            error: value ? "" : t("messages.emptyField")
        }
    }

    const handleNumberClick = (number: any) => {
        if (!target) return;
        if (target === "en") setEmployeeNumber((prev) => prev + number);
        if (target === "pn") setPincode((prev) => prev + number);
    };

    const handleDelete = () => {
        if (!target) return;
        if (target === "en") setEmployeeNumber((prev) => prev.slice(0, -1));
        if (target === "pn") setPincode((prev) => prev.slice(0, -1));
    };

    return (
        <>
            <div className={`${styles.textFieldsWrapper} ${showNumberPad ? "scroll" : ""}`}>
                <TextField
                    label={t("messages.employeeNo")}
                    name="employeeNumber"
                    value={employeeNumber}
                    placeholder={t("messages.employeeNo")}
                    onChange={(e) =>
                        setEmployeeNumber((e.target as HTMLTextAreaElement).value)
                    }
                    validators={[isRequiredFilled,]}
                    type="number"
                    inputMode="numeric"
                    readOnly
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setTarget("en")
                        if (showNumberPad) {
                            return;
                        }
                        setShowNumberPad(true)
                    }}
                    style={{ border: target === "en" ? "1px blue solid" : "" }}
                />
                <TextField
                    value={pincode}
                    label={t("messages.pincode")}
                    name="pincode"
                    placeholder={t("messages.pincode")}
                    onChange={(e) =>
                        setPincode((e.target as HTMLTextAreaElement).value)
                    }
                    validators={[isRequiredFilled,]}
                    type="number"
                    inputMode="numeric"
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setTarget("pn")
                        if (showNumberPad) {
                            return;
                        }
                        setShowNumberPad(true)
                    }}
                    readOnly
                    style={{ border: target === "pn" ? "1px blue solid" : "", background: "white" }}
                />
            </div>
            <NumberPad open={showNumberPad}
                onClose={() => {
                    setShowNumberPad(false);
                    setTarget("");
                }}
                onNumberClick={handleNumberClick}
                onDelete={handleDelete}
            />
        </>
    );
};

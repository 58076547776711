import axios from "axios";

export const clockUser = async (
  token: string,
  employeeNumber: string,
  pincode: string
) => {
  const url = `/api/clock/`;
  const headers = {
    Accept: "application/json",
    Authorization: token || "",
  };

  const body = { employeeNumber, pincode };

  return await axios.post(url, body, { headers: headers });
};

export const checkIp = async (
  token: string
) => {
  const url = `/api/clock/checkIp`;
  const headers = {
    Accept: "application/json",
    Authorization: token || "",
  };

  return await axios.get(url, { headers });
};

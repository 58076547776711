import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useInterval } from "react-use";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/nl"
import { MainBox } from "./layout/mainBox/MainBox";
import { useState, useEffect } from "react";
import { Errors } from "./components/errors/Errors";

import "./App.scss";
import { getColorBasedOnStatus } from "./services/Utils";
import { checkIp, clockUser } from "./services/Api";
import { AxiosError } from "axios";

export enum ClockStatus {
    OFFLINE,
    WRONG_CREDS,
    CLOCKED_IN,
    CLOCKED_OUT,
    OWS_OFFLINE,
    IP_NOT_ALLOWED
}

interface ErrorObj {
    code: number;
    msg: string;
    ipError?: boolean;
}

function App() {
    let location = useLocation();
    let query = new URLSearchParams(location.search);
    const token: string = query.get("token") || "";
    const lang: string = query.get("lang") || "nl";

    const { t, i18n } = useTranslation();

    const [hasErrors, setHasErrors] = useState<ErrorObj | undefined>(undefined);

    const [clockStatus, setClockStatus] = useState<ClockStatus>(
        ClockStatus.OFFLINE
    );

    const [liveDateTime, setLiveDateTime] = useState<Dayjs>(dayjs());

    useEffect(() => {
        if (!token) {
            setHasErrors({ code: 0, msg: `Missing authorisation token.` });
            return;
        }

        isIpAllowed(token);

        i18n.changeLanguage(lang);
    }, []);

    useInterval(() => {
        setLiveDateTime(dayjs());
    }, 1000);

    async function isIpAllowed(token: string) {
        try {
            await checkIp(token);
        } catch (error) {
            if (error instanceof AxiosError) {
                if (error.response?.data?.message === "ip-not-allowed") {
                    const code = error?.response?.data.statusCode || 0;
                    const msg = t("messages.ipNotAllowed");
                    setHasErrors({ code, msg, ipError: true });
                }
            }
        }
    }

    const clockUserRequest = async (
        employeeNumber: string,
        pincode: string
    ) => {
        try {
            const { data } = await clockUser(
                token,
                employeeNumber,
                pincode
            );
            setClockStatus(data);

            return;
        } catch (error) {
            console.error(error);

            if (error instanceof AxiosError) {
                if (error.response?.data?.message === "ip-not-allowed") {
                    setClockStatus(ClockStatus.IP_NOT_ALLOWED)
                    return;
                }
                const code = error?.response?.data.statusCode || 0;
                const msg = `We are very sorry but something went wrong with your clocking.`;

                setHasErrors({ code, msg });
            }
        }
    };

    return (
        <div className="app">
            {hasErrors ? (
                <Errors
                    msg={
                        hasErrors.msg ||
                        `Something went wrong with your clocking`
                    }
                    errorCode={hasErrors.code}
                    ipError={hasErrors.ipError}
                />
            ) : (
                <>
                    <div className="foreground">
                        <div
                            className="timeAndDateContainter"
                            style={{
                                backgroundColor: `${getColorBasedOnStatus(
                                    clockStatus
                                )}`,
                            }}
                        >
                            <div className="timeLabel">
                                <div className="date">
                                    {liveDateTime.locale(lang).format("dddd, DD MMMM")}
                                </div>
                                <div className="time">
                                    {liveDateTime.format("HH:mm:ss")}
                                </div>
                            </div>
                        </div>
                        <div
                            className="mainBoxWrapper"
                            style={{
                                background: `linear-gradient(to bottom, ${getColorBasedOnStatus(
                                    clockStatus
                                )} 50%, white 50%`,
                            }}
                        >
                            <MainBox
                                status={clockStatus}
                                changeStatus={setClockStatus}
                                time={liveDateTime.format("HH:mm:ss")}
                                clockUser={clockUserRequest}
                            />
                        </div>
                        <div className="helpNoteContainer">
                            <div className="helpMeNote">
                                {t("messages.helpText")}
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default App;

import { FunctionComponent } from "react";

import styles from "./Errors.module.scss";

interface IErrorProps {
	msg: string;
	errorCode?: number;
	ipError?: boolean
}

export const Errors: FunctionComponent<IErrorProps> = (props: IErrorProps) => {
	const { msg, errorCode, ipError } = props;

	return (
		<div className={styles.errorsContainer}>
			<div className="o4c card errors">
				<div className="header">
					<div>
						<i className="icon-alert leading" />{" "}
						{"Something went wrong"}
					</div>
				</div>
				<div className="main">
					{errorCode ? (
						<p>
							<strong>{`Error ${errorCode} - ${msg}`}</strong>
						</p>
					) : (
						<p>
							<strong>{msg}</strong>
						</p>
					)}
					{
						ipError ? null :
							<p>
								{
									"Please contact your system administrator to solve this issue."
								}
							</p>
					}
				</div>
			</div>
		</div>
	);
};

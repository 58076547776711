import { Icon } from "@o4c/plugin-components";
import { FunctionComponent, useState } from "react";
import { ClockStatus } from "../../App";
import { LoginPage } from "../../components/loginPage/LoginPage";
import { CheckInPage } from "../../components/checkInPage/CheckInPage";
import { RegisterButton } from "../../components/registerButton/RegisterButton";
import { TimerButton } from "../../components/timerButton/TimerButton";

import styles from "./MainBox.module.scss";
import { useTranslation } from "react-i18next";

interface IMainBoxProps {
    status: ClockStatus;
    changeStatus: (status: ClockStatus) => void;
    time: string;
    clockUser: (employeeNumber: string, pincode: string) => Promise<void>;
}

export const MainBox: FunctionComponent<IMainBoxProps> = (
    props: IMainBoxProps
) => {
    const { status, time, changeStatus, clockUser } = props;

    const wrongCreds = status === ClockStatus.WRONG_CREDS
    const ipNotAllowed = status === ClockStatus.IP_NOT_ALLOWED

    const [employeeNumber, setEmployeeNumber] = useState<string>("");
    const [pincode, setPincode] = useState<string>("");
    const [clockedTime, setClockedTime] = useState<string>("");

    const { t } = useTranslation();

    const renderContent = () => {
        if (
            status === ClockStatus.OFFLINE ||
            status === ClockStatus.WRONG_CREDS ||
            status === ClockStatus.IP_NOT_ALLOWED
        ) {
            return (
                <LoginPage
                    changeEmployeeNumber={setEmployeeNumber}
                    changePincode={setPincode}
                />
            );
        }

        return <CheckInPage status={status} time={clockedTime} />;
    };

    const handleRegister = async () => {
        setClockedTime(time);
        await clockUser(employeeNumber, pincode);
    }

    const renderButton = () => {
        if (
            status === ClockStatus.OFFLINE ||
            status === ClockStatus.WRONG_CREDS ||
            status === ClockStatus.IP_NOT_ALLOWED
        ) {
            return (
                <RegisterButton
                    wrongCreds={wrongCreds}
                    ipNotAllowed={ipNotAllowed}
                    onRegister={handleRegister}
                />
            );
        }

        return <TimerButton timer={10} changeStatus={changeStatus} />;
    };

    return (
        <div className={styles.registerBox}>
            <div className={styles.title}>
                <Icon
                    name="time"
                    color="primary"
                    className={styles.titleIcon}
                    size="medium"
                />
                <div className={styles.titleText}>{t("messages.boxTitle")}</div>
            </div>
            <div className={styles.content}>
                <div className={styles.textFields}>{renderContent()}</div>
            </div>
            <div className={styles.footer}>
                <div className={styles.boxButton}>{renderButton()}</div>
            </div>
        </div>
    );
};

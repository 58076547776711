import { ClockStatus } from "../App";

export const getColorBasedOnStatus = (_status: ClockStatus) => {
    switch (_status) {
        case ClockStatus.WRONG_CREDS:
        case ClockStatus.IP_NOT_ALLOWED:
            return `var(--negative)`;
        case ClockStatus.CLOCKED_IN:
        case ClockStatus.CLOCKED_OUT:
        case ClockStatus.OWS_OFFLINE:
            return `var(--positive)`;
        default:
            return `var(--primary)`;
    }
};
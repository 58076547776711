import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { ClockStatus } from "../../App";
import { getColorBasedOnStatus } from "../../services/Utils";

import styles from "./CheckInPage.module.scss";

interface ICheckInPageProps {
    status: ClockStatus;
    time: string;
}

export const CheckInPage: FunctionComponent<ICheckInPageProps> = (
    props: ICheckInPageProps
) => {
    const { status, time } = props;

    const { t } = useTranslation();

    return (
        <div className={styles.checkIn}>
            <div className={styles.infoText}>
                {t("messages.checkInMsg")} <HighlightedText status={status} /> {t("messages.at")}
            </div>
            <div className={styles.timeBox}>{time}</div>
        </div>
    );
};

interface IHighlightedTextProps {
    status: ClockStatus
}

const HighlightedText: FunctionComponent<IHighlightedTextProps> = (props: IHighlightedTextProps) => {
    const { status } = props;
    const { t } = useTranslation();

    if (status === ClockStatus.CLOCKED_IN) {
        return (
            <span
                style={{
                    color: `${getColorBasedOnStatus(status)}`,
                }}
            >
                {t("messages.clockedIn")}
            </span>
        );
    }

    if (status === ClockStatus.OWS_OFFLINE) {
        return (
            <span
                style={{
                    color: `${getColorBasedOnStatus(status)}`,
                }}
            >
                {t("messages.owsOffline")}
            </span>
        );
    }

    return (
        <span
            style={{
                color: `${getColorBasedOnStatus(status)}`,
            }}
        >
            {t("messages.clockedOut")}
        </span>
    );
}
import { Button } from "@o4c/plugin-components";
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";

import styles from "./RegisterButton.module.scss"

interface IRegisterButtonProps {
    wrongCreds: boolean;
    ipNotAllowed: boolean;
    onRegister: () => Promise<void>;
}

export const RegisterButton: FunctionComponent<IRegisterButtonProps> = (
    props: IRegisterButtonProps
) => {
    const [loading, setLoading] = useState<boolean>(false);
    const { wrongCreds, ipNotAllowed, onRegister } = props;

    const { t } = useTranslation();

    const handleClickRegister = async () => {
        setLoading(true)
        await onRegister()
        setLoading(false)
    }

    return (
        <>
            {wrongCreds && (
                <div className={styles.errorMsg}>{t("messages.wrongCreds")}</div>
            )}
            {ipNotAllowed && (
                <div className={styles.errorMsg}>{t("messages.ipNotAllowed")}</div>
            )}
            {
                loading ? (
                    <Button
                        display="block"
                        color="default"
                        type="outline"
                        onClick={handleClickRegister}
                        floating
                        disabled>
                        <div className={styles.loaderText}>submitting...</div>
                    </Button>
                ) : (
                    <Button
                        display="block"
                        color="accent"
                        onClick={handleClickRegister}
                        floating>
                        <div className={styles.buttonText}>{t("messages.register")}</div>
                    </Button>
                )
            }
        </>
    )
}

import { Button, Snackbar } from '@mui/material';
import BackspaceIcon from '@mui/icons-material/Backspace';
import styles from "./Numpad.module.scss";

const NumberPad = ({ open, onClose, onNumberClick, onDelete }: any) => {
    const numbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9'];

    return (
        <Snackbar
            open={open}
            onClose={onClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transitionDuration={{ enter: 300, exit: 300 }}
            sx={{ width: '100%', bottom: "0px !important", left: "0px !important", right: "0px !important" }}
        >
            <div className={styles.container}>
                <div className={styles.wrapper}>
                    {numbers.map((number, index) =>
                        <Button
                            key={number}
                            color="primary"
                            onClick={() => onNumberClick(number)}
                        >
                            {number}
                        </Button>
                    )}
                    <Button disabled color="secondary"></Button>
                    <Button
                        onClick={() => onNumberClick(0)}
                    >0</Button>
                    <Button
                        onClick={() => onDelete()}
                    ><BackspaceIcon /></Button>
                </div>
            </div>
        </Snackbar >
    );
};

export default NumberPad;
